var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-container",
    { staticClass: "legal-requests-page-component" },
    [
      _c(
        "masthead",
        {
          attrs: {
            "super-title": "Law On Call",
            title: "Legal Requests",
            "filter-options": _vm.filterOptions,
            "should-display-date-range": true,
            "divide-button-groups": "",
            "show-search-input": false,
          },
          on: { changed: _vm.searchChanged },
        },
        [
          _c(
            "b-button",
            {
              class: _vm.$mq !== "sm" ? "rounded" : "",
              attrs: { variant: "primary", disabled: _vm.downloadBtnDisabled },
              on: { click: _vm.downloadSelection },
            },
            [
              _c("feather-icon", { attrs: { type: "download" } }),
              _vm._v("\n      " + _vm._s(_vm.downloadBtnText) + "\n    "),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "float-right" },
            [
              _c(
                "b-button",
                {
                  staticClass: "btn btn-preferred",
                  attrs: {
                    to: "/law-on-call/new-legal-request",
                    variant: "primary",
                  },
                },
                [
                  _c("feather-icon", { attrs: { type: "plus" } }),
                  _vm._v("\n        New Legal Request\n      "),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("template", { slot: "title" }, [_vm._v("\n    Legal Documents\n  ")]),
      _c("ajax-table", {
        ref: "legalRequestsTable",
        staticClass: "legal-requests-table",
        attrs: {
          striped: "",
          "select-mode": "multi",
          selectable: true,
          "table-definition": _vm.legalRequestsQueueTableDefinition,
          "selectable-function": _vm.selectableFunction,
        },
        on: { "selection-changed": _vm.selectionEvent, loaded: _vm.onLoad },
        scopedSlots: _vm._u([
          {
            key: "cell(status)",
            fn: function (row) {
              return [
                _vm._v(
                  "\n      " +
                    _vm._s(_vm.capitalize(row.item.status)) +
                    "\n    "
                ),
              ]
            },
          },
          {
            key: "cell(tos_agreement)",
            fn: function (row) {
              return [
                _c(
                  "b-button",
                  {
                    staticClass: "mr-1",
                    attrs: { variant: "link" },
                    on: {
                      click: function ($event) {
                        return _vm.viewToS(row.item.tos_agreement)
                      },
                    },
                  },
                  [_vm._v("\n        View\n      ")]
                ),
              ]
            },
          },
          {
            key: "cell(jurisdiction)",
            fn: function (row) {
              return [
                _vm._v(
                  "\n      " + _vm._s(_vm.jurisdictionFor(row.item)) + "\n    "
                ),
              ]
            },
          },
          {
            key: "cell(actions)",
            fn: function (row) {
              return [
                row.item.lock_data && row.item.status === "completed"
                  ? [
                      _c(
                        "b-button",
                        {
                          staticClass: "mr-1 p-0",
                          attrs: { variant: "link" },
                          on: {
                            click: function ($event) {
                              return _vm.unlockOrDownload(row.item)
                            },
                          },
                        },
                        [
                          _c("feather-icon", {
                            attrs: {
                              type: row.item.payment_locked
                                ? "lock"
                                : "download",
                              width: "18",
                              height: "18",
                            },
                          }),
                          _vm._v(
                            "\n          " +
                              _vm._s(
                                row.item.payment_locked ? "Unlock" : "Download"
                              ) +
                              "\n        "
                          ),
                        ],
                        1
                      ),
                    ]
                  : _vm._e(),
              ]
            },
          },
        ]),
      }),
      _c("doc-lock-modal", {
        attrs: { document: _vm.viewedDocument, bus: _vm.bus },
      }),
      _c("doc-lock-pay-modal", {
        attrs: { bus: _vm.bus },
        on: { completed: _vm.paymentCompletedDocLock },
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }