<template>
  <b-container class="legal-requests-page-component">
    <masthead
      super-title="Law On Call"
      title="Legal Requests"
      :filter-options="filterOptions"
      :should-display-date-range="true"
      divide-button-groups
      :show-search-input="false"
      @changed="searchChanged"
    >
      <b-button
        variant="primary"
        :disabled="downloadBtnDisabled"
        :class="$mq !== 'sm' ? 'rounded' : ''"
        @click="downloadSelection"
      >
        <feather-icon type="download" />
        {{ downloadBtnText }}
      </b-button>
      <div class="float-right">
        <b-button
          :to="'/law-on-call/new-legal-request'"
          variant="primary"
          class="btn btn-preferred"
        >
          <feather-icon type="plus" />
          New Legal Request
        </b-button>
      </div>
    </masthead>

    <template slot="title">
      Legal Documents
    </template>

    <ajax-table
      ref="legalRequestsTable"
      class="legal-requests-table"
      striped
      select-mode="multi"
      :selectable="true"
      :table-definition="legalRequestsQueueTableDefinition"
      :selectable-function="selectableFunction"
      @selection-changed="selectionEvent"
      @loaded="onLoad"
    >
      <template slot="cell(status)" slot-scope="row">
        {{ capitalize(row.item.status) }}
      </template>
      <template slot="cell(tos_agreement)" slot-scope="row">
        <b-button
          variant="link"
          class="mr-1"
          @click="viewToS(row.item.tos_agreement)"
        >
          View
        </b-button>
      </template>
      <template slot="cell(jurisdiction)" slot-scope="row">
        {{ jurisdictionFor(row.item) }}
      </template>
      <template slot="cell(actions)" slot-scope="row">
        <template v-if="row.item.lock_data && row.item.status === 'completed'">
          <b-button
            variant="link"
            class="mr-1 p-0"
            @click="unlockOrDownload(row.item)"
          >
            <feather-icon
              :type="row.item.payment_locked ? 'lock' : 'download'"
              width="18"
              height="18"
            />
            {{ row.item.payment_locked ? 'Unlock' : 'Download' }}
          </b-button>
        </template>
      </template>
    </ajax-table>

    <doc-lock-modal :document="viewedDocument" :bus="bus" />
    <doc-lock-pay-modal :bus="bus" @completed="paymentCompletedDocLock" />
  </b-container>
</template>

<script>
import FeatherIcon from '@/components/shared/FeatherIcon'
import Masthead from '@/components/shared/Masthead'
import { mapGetters } from 'vuex'
import { formatDateString } from '@/common/modules/formatters'
import Vue from 'vue'
import DocLockModal from '@/components/DocLockModal'
import DocLockPayModal from '@/components/DocLockPayModal'
import _ from 'lodash'
import AjaxTable from '../../components/shared/AjaxTable'
import http from '@/http'

export default {
  name: 'LegalRequests',
  components: {
    AjaxTable,
    FeatherIcon,
    Masthead,
    DocLockModal,
    DocLockPayModal,
  },
  data() {
    return {
      dataCount: null,
      query: null,
      appliedFilters: [],
      select: null,
      selectAll: false,
      selection: [],
      showModalErrorAlert: false,
      companies: [],
      viewedDocument: null,
      currentPage: 1,
      bus: new Vue(),
      filterOptions: {
        radioGroups: [
          {
            key: 'lock_status',
            filters: [
              { key: 'lock1', scope: 'where_new', value: true, label: 'New' },
              { key: 'lock2', scope: 'by_locked', value: true, label: 'Locked' },
              { key: 'lock3', scope: 'by_locked', value: false, label: 'Not Locked' },
            ],
          },
        ],
      },
    }
  },
  computed: {
    ...mapGetters({
      urls: 'documents/urls',
    }),
    // The documents that are selected and not "payment locked".
    selectedUnlocked() {
      return this.selection.filter(document => !this.isLocked(document))
    },
    selectedLocked() {
      return this.selection.filter(document => this.isLocked(document))
    },
    downloadBtnText() {
      if (this.selection.length > 0 && this.$mq !== 'sm') {
        const blocked = this.selectedLocked.length
        const downloadable = this.selectedUnlocked.length
        const suffix = downloadable > 1 || downloadable === 0 ? 's' : ''
        const warning = blocked >= 1 ? `(${blocked} Locked)` : ''
        return `Download ${downloadable} Document${suffix} ${warning}`
      } else {
        return 'Download'
      }
    },
    downloadBtnDisabled() {
      return this.selectedUnlocked.length === 0
    },
    legalRequestsQueueTableDefinition() {
      return {
        columns: [
          { label: 'Status',         key: 'status',          sortable: true, selectable: true },
          { label: 'Title',          key: 'title_formatted', sortable: true, selectable: true },
          { label: 'ToS Agreement',  key: 'tos_agreement',                   selectable: true,
            tdClass: 'pl-0',
          },
          {                          key: 'jurisdiction',    sortable: true, selectable: true },
          { label: 'Company',        key: 'company_name',    sortable: true, selectable: true },
          { label: 'Date Ordered',   key: 'created_at',      sortable: true, selectable: true,
            formatter: value => this.dateTableColumn(value),
          },
          { label: 'Actions',        key: 'actions' },
        ],
        url: 'client/legal_requests',
        parameters: {
          search_name: 'search_legal_requests_page',
          query: this.query,
          filter: this.appliedFilters,
        },
      }
    },
    confirmDisabled() {
      return !(this.uploadedDocument && this.companyUploadSelection)
    },
  },
  async mounted() {
    this.bus.$on('payDocLock', this.payDocLock)
    while(this.dataCount == null) {
      await new Promise( r => setTimeout(r, 200) )
    }
  },
  methods: {
    isLocked(item) {
      return item.payment_locked
    },
    dateTableColumn(date) {
      return date === 'Invalid Date' ? '---' : this.formatDate(date)
    },
    selectableFunction(item) {
      return item.payment_locked === false
    },
    selectionEvent(selection) {
      this.selection = selection
    },
    jurisdictionFor(item) {
      return item.legal_spec.jurisdiction.state_province_region
    },
    capitalize: input => _.capitalize(input),
    async downloadSelection() {
      if (this.selectedUnlocked.length > 0) {
        const ids = this.selectedUnlocked.map(record => record.completed_legal_request_id)

        try {
          const result = await http.post('client/completed_legal_requests/bulk_download', {
            ids: ids,
          })

          if (result.data.result.url) {
            let interval = 1000
            result.data.result.url.forEach(function(value, index) {
              setTimeout(() => {
                window.location = value
              }, interval * (index + 1))
            })
          }
        } catch (error) {
          console.error(error)
        }
      }
    },
    searchChanged(searchObject) {
      this.appliedFilters = searchObject.filters
    },
    cancel() {
      this.$refs.documentUpload.hide()
    },
    onLoad(tableData) {
      this.dataCount = tableData.length
    },
    resetModal() {
      this.uploadedDocument = null
      this.companyUploadSelection = null
    },
    companySelected(companyId) {
      this.companyUploadSelection = companyId
    },
    resetErrorAlert() {
      this.showModalErrorAlert = false
    },
    async unlockOrDownload(document) {
      this.viewedDocument = document

      if (this.isLocked(document)) {
        this.bus.$emit('open', this.viewedDocument)
      } else {
        this.selection = [this.viewedDocument]
        await this.downloadSelection()
      }
    },
    payDocLock(params) {
      this.bus.$emit('showDockLockPayModal', params)
    },
    async paymentCompletedDocLock() {
      this.$bvModal.hide('dockLockPayModal')
      this.$bvToast.toast('Document is Successfully Unlocked', {
        title: 'Payment Success',
        variant: 'success',
        solid: true,
        autoHideDelay: 10000,
      })

      this.viewedDocument.payment_locked = false
      this.selection = [this.viewedDocument]
      await this.downloadSelection()
      await http.patch(`client/completed_legal_requests/${this.viewedDocument.completed_legal_request_id}/mark_read`)
    },
    async viewToS(item) {
      await this.$router.push({ name: 'document', params: { id: item.id } })
    },
    formatDate: formatDateString,
  },
}
</script>

<style lang="scss">
.legal-requests-page-component {
  .modal-title {
    width: 100%;
    display: inline-block;
    font-size: 28px;
  }

  .fixed-btn-group {
    position: fixed;
    width: 100%;
    background-color: white;
    z-index: 1000;
  }

  .legal-requests-table {
    font-size: .9em;
  }

  .table-header {
    position: fixed !important;
  }

  @media only screen and (min-width: 736px) {
    // Status
    td[aria-colindex='2'] {
      width: 80px;
      padding-left: 0;
      padding-right: 20px;
      .expandable-row {
        padding-left: 0;
      }
    }

    // Title
    td[aria-colindex='3'] {
      width: 270px;
      max-width: 270px;
      padding-left: 0;
      .expandable-row {
        padding-left: 0;
      }
    }

    // Actions
    td[aria-colindex='7'] {
      width: 140px;
      padding-left: 0;
      padding-right: 0;
    }
  }

}

</style>
